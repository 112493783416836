import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomText,
  ExperienceItem,
  Spinner,
  TimeLine,
} from '../../component'
import {
  Fields,
  globalDateFormat,
  PermitStatus,
  PermitStepAction,
  PermitStepType,
} from '../../helpers'
import {
  BlueDraftIcon,
  DraftCheck2Icon,
  DraftCheckIcon,
  DraftIssuedIcon,
  DraftRejectIcon,
  DraftShareIcon,
} from '../../icons'
import { usePermitHandle, usePostSignFlowActivate } from '../../packages/api-web'

function DrivingLicenseDetails() {
  const { t } = useTranslation('common')
  const { id } = useParams()
  const navigate = useNavigate()
  const { isLoading, data } = usePermitHandle({ handle: id })
  const { isPending: isSignActivate, mutate: postSignActivate } = usePostSignFlowActivate()

  const permitsItem = useMemo(() => {
    const result = data?.item
    return result
  }, [data])

  const step = [
    {
      icon: <BlueDraftIcon />,
      title: t('draftCreated'),
      description: `${globalDateFormat(permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.draft)?.timestamp ?? '')}\n${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.draft)?.initiatedBy?.displayName ?? ''}`,
      active: permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.draft),
    },
    {
      icon: (
        <DraftShareIcon
          isEnable={
            !!permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.submitted)
          }
        />
      ),
      title: t('sentForSignature'),
      description: `${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.submitted) ? globalDateFormat(permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.submitted)?.timestamp ?? '') : '[YYYY-MM-DD]'}\n${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.submitted)?.initiatedBy?.displayName ?? `[${t('admin')} / ${t('creator')}]`}`,
      active: permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.submitted),
    },
    {
      icon: permitsItem?.approvalSteps?.find(i => i?.stepAction === PermitStepAction.rejected)
        ? (
            <DraftRejectIcon />
          )
        : (
            <DraftCheckIcon
              isEnable={
                !!permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature)
              }
            />
          ),
      title: t('signatureResponsible'),
      description: `${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature) ? (permitsItem?.approvalSteps?.find(i => i?.stepAction === PermitStepAction.rejected) ? `${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature)?.details}\n` : '') : ''}${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature) ? globalDateFormat(permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature)?.timestamp ?? '') : '[YYYY-MM-DD]'}\n${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature)?.initiatedBy?.displayName ?? `[${t('responsible')}]`}`,
      active: permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.issuerSignature),
    },
    {
      icon: (
        <DraftCheck2Icon
          isEnable={
            !!permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.holderSignature)
          }
        />
      ),
      title: t('receiptEmployee'),
      description: `${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.holderSignature) ? globalDateFormat(permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.holderSignature)?.timestamp ?? '') : '[YYYY-MM-DD]'}\n${permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.holderSignature)?.initiatedBy?.displayName ?? `[${t('employee')}]`}`,
      active: permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.holderSignature),
    },
    {
      icon: (
        <DraftIssuedIcon
          stepAction={
            permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.finalized)
              ?.stepAction
          }
        />
      ),
      title: t('drivingLicenseIssued'),
      description: permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.finalized)
        ? permitsItem?.approvalSteps?.find(
          i =>
            i?.stepType === PermitStepType.finalized
            && i?.stepAction === PermitStepAction?.approved,
        )
          ? t('licenseIssued')
          : t('licenseNotIssued')
        : '',
      active: permitsItem?.approvalSteps?.find(i => i?.stepType === PermitStepType.finalized),
    },
  ]

  const getButtonName = () => {
    switch (permitsItem?.status) {
      case PermitStatus.draft:
        return t('submitForSign')
      case PermitStatus.waitingSignature:
        return t('cancelSigning')
      case PermitStatus.active:
        return t('download')
      default:
        return ''
    }
  }

  const onTapSign = () => {
    if (permitsItem?.status == PermitStatus.draft) {
      const data = {
        permitHandle: permitsItem?.handle,
      }
      postSignActivate(data)
    }
    else if (permitsItem?.status == PermitStatus.waitingSignature) {
      // Call the cancle permits sign API
    }
    else {
      // Download PDF
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {isSignActivate && <Spinner />}
      <CustomText variant="xlPoppinsBold">{t('drivingLicense')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {`${t('drivingLicenseFor')} ${permitsItem?.holder?.displayName}`}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg">
            <div className="flex-1 px-5 py-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="flex flex-1 flex-col">
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldDrivingLicense')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      {permitsItem?.holder?.displayName ?? ''}
                    </CustomText>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldSection')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        {permitsItem?.holder?.jobTitle ?? ''}
                      </CustomText>
                    </div>
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldBusiness')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        {permitsItem?.holder?.department ?? ''}
                      </CustomText>
                    </div>
                  </div>
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldSignDrivingPermit')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      {permitsItem?.issuer?.displayName ?? ''}
                    </CustomText>
                  </div>
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldTrainer')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      {permitsItem?.fields?.find(i => i?.name == Fields?.trainer)?.value ?? ''}
                    </CustomText>
                  </div>
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldTools')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      {permitsItem?.fields?.find(i => i?.name == Fields?.tools)?.value ?? ''}
                    </CustomText>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldValidFrom')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        {globalDateFormat(permitsItem?.startDate ?? '')}
                      </CustomText>
                    </div>
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldValidTo')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        {globalDateFormat(permitsItem?.endDate ?? '')}
                      </CustomText>
                    </div>
                  </div>
                  <CustomText variant="mdPoppinsSemiBold">
                    {t('fieldDrivingLicenseRefers')}
                  </CustomText>
                  <div className="mt-4 flex gap-4 flex-wrap">
                    {permitsItem?.certificates?.map(i => (
                      <ExperienceItem title={i.title ?? ''} image={i?.imageMediaItemDto?.url} />
                    ))}
                  </div>
                </div>
                <div className="flex flex-1 flex-col items-center">
                  <TimeLine step={step} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-4 justify-center sm:justify-end py-4 rounded-md">
          <BorderButton
            children={t('back')}
            className="!px-14"
            textColor="text-textPrimary"
            onClick={() => {
              navigate(-1)
            }}
          />
          {(permitsItem?.status == PermitStatus.draft
            || permitsItem?.status == PermitStatus.waitingSignature
            || permitsItem?.status == PermitStatus.active) && (
            <CustomButton
              children={getButtonName()}
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={onTapSign}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DrivingLicenseDetails
