import type { CertificateItems } from '../../../packages/api-web'
import { States } from '@inphiz-shared/shared-models/src/F_Certificates/UserCertificateConstants/states'
import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'rsuite'
import { SortIcon } from '../../../icons'
import { useCertificateItems, useCertificateList } from '../../../packages/api-web'
import { BorderButton, CustomAlert, CustomButton, CustomText, Spinner, TagView } from '../../atoms'

interface GroupMembersProps {
  selectedItems?: CertificateItems[]
  handle?: string
  onCloseAlert?: () => void
  selectedCertificates?: (items: CertificateItems[]) => void
}

export function AddCertificateModal({
  selectedItems,
  handle,
  onCloseAlert,
  selectedCertificates,
}: GroupMembersProps) {
  const { t } = useTranslation('common')
  const [sorting, setSorting] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<CertificateItems[]>([])
  const [certificateList, setCertificateList] = useState<CertificateItems[]>([])
  const [filter, setFilter] = useState([
    { id: 1, title: t('activeExperiences'), isFilter: true },
    { id: 2, title: t('all'), isFilter: false },
  ])
  const { data: certificateListData } = useCertificateList({ isPermits: true })
  const { data: certificateItems, isLoading } = useCertificateItems({
    handle,
    skip: 0,
    itemPerPage: 0,
  })

  useEffect(() => {
    setSelectedItem(selectedItems ?? [])
    if (filter?.find(i => i?.isFilter && i?.id == 1)) {
      const permitsFilter = certificateListData?.items
        ?.map(i => certificateItems?.items?.find(j => j?.certificateHandle === i?.handle))
        .filter(Boolean) as CertificateItems[]
      const filter = permitsFilter?.filter(i => i?.state?.title === States.active)
      setCertificateList(filter ?? [])
    }
    else {
      const permitsFilter = certificateListData?.items
        ?.map(i => certificateItems?.items?.find(j => j?.certificateHandle === i?.handle))
        .filter(Boolean) as CertificateItems[]
      setCertificateList(permitsFilter ?? [])
    }
  }, [filter, certificateItems])

  const renderItem = (item: CertificateItems, idx: string) => {
    return (
      <div
        className={`flex flex-1 h-12 pl-4 items-center ${Number.parseInt(idx) != certificateList?.length - 1 ? 'border-b' : ''} border-stroke ${Number.parseInt(idx) % 2 == 0 ? 'bg-table-odd-bg' : ''}`}
      >
        <div className="flex items-center flex-1 h-12 border-r border-stroke text-left">
          <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
          <div className="ml-4">
            <TagView
              {...(item?.state == null && { className: 'bg-draft' })}
              title={item?.state ? item?.state?.title : item?.status?.name}
              {...(item?.state && { backgroundColor: item?.state?.backgroundColor?.colorHex })}
            />
          </div>
        </div>
        <Checkbox
          readOnly={item?.state?.title !== States.active}
          checked={selectedItem?.includes(item)}
          onChange={() => {
            if (selectedItem?.includes(item)) {
              const temp = [...selectedItem]
              const index = selectedItem.indexOf(item)
              temp.splice(index, 1)
              setSelectedItem(temp)
            }
            else {
              setSelectedItem([...selectedItem, item])
            }
          }}
        />
      </div>
    )
  }

  if (isLoading)
    return <Spinner />

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {/* {isPending && <Spinner />} */}
      <CustomText variant="mdPoppinsSemiBold">{t('addExperience')}</CustomText>
      <div className="border border-stroke rounded-md mt-4">
        <div className="flex p-3 border-b border-stroke gap-2">
          {filter?.map((i) => {
            return (
              <button
                onClick={() => {
                  setFilter(prevFilters =>
                    prevFilters.map(item => ({
                      ...item,
                      isFilter: item.id === i?.id,
                    })),
                  )
                }}
              >
                <TagView
                  className={`border-secondary border ${i?.isFilter ? 'bg-secondary' : ''}`}
                  title={i?.title}
                  textColor={i?.isFilter ? 'text-textWhite' : 'text-textPrimary'}
                />
              </button>
            )
          })}
        </div>
        <div
          className="flex items-center h-12 px-4 border-b border-stroke"
          onClick={() => {
            const temp = [...certificateList]
            if (sorting == '' || sorting == 'asc') {
              setCertificateList(
                temp.sort((a: any, b: any) => (a.displayName > b.displayName ? -1 : 1)),
              )
              setSorting('des')
            }
            else {
              setCertificateList(
                temp.sort((a: any, b: any) => (a.displayName > b.displayName ? 1 : -1)),
              )
              setSorting('asc')
            }
          }}
        >
          <CustomText variant="mdPoppinsMedium">{t('experiences')}</CustomText>
          <SortIcon
            className={`h-5 w-5 ml-4 stroke-[#C9C9C9] ${sorting === 'asc' ? 'rotate-180' : ''}`}
          />
        </div>
        <div className="max-h-52 overflow-hidden overflow-y-auto scrollbar">
          <FlatList
            list={certificateList}
            renderItem={renderItem}
            renderWhenEmpty={() => {
              return (
                <div className="h-10 flex flex-1 items-center justify-center">
                  <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
                </div>
              )
            }}
          />
        </div>
        <div className="flex py-4 px-4 justify-end border-t border-stroke">
          <div className="flex gap-4 flex-wrap justify-end">
            <BorderButton
              children={t('cancel')}
              className="!px-2 flex-1"
              textColor="text-textPrimary"
              onClick={() => {
                onCloseAlert?.()
              }}
            />
            <CustomButton
              children={t('clear')}
              className="!px-4 flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {
                setSelectedItem([])
              }}
            />
            <CustomButton
              children={t('add')}
              className="px-4 sm:px-8 bg-secondary"
              textColor="text-textWhite"
              onClick={() => {
                selectedCertificates?.(selectedItem)
                onCloseAlert?.()
              }}
            />
          </div>
        </div>
      </div>
    </CustomAlert>
  )
}
