import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

export interface IndustriesItem {
  title?: string
  handle?: string
}

const IndustriesDto = new DataCollectionApiResponse<IndustriesItem>()
export type IndustriesData = typeof IndustriesDto

interface Params {
  itemPerPage?: number
  skip?: number
}

export function useIndustries({ itemPerPage = 2147483647, skip = 0 }: Params) {
  return useQuery<IndustriesData>({
    queryKey: [QueryKey.getIndustries],
    queryFn: () => {
      const qb = new QueryBuilder(APIConst.industry.industries)
        .add('cursor', skip)
        .add('take', itemPerPage)
      return APIFunctions.GET(qb.toString())
    },
  })
}
