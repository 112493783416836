import type { CertificateItem } from '../Employee'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

const CertificateItemDto = new DataItemApiResponse<CertificateItem>()
export type CertificateItemData = typeof CertificateItemDto

interface Params {
  handle?: string
}

export function useCertificatesItem({ handle }: Params) {
  return useQuery<CertificateItemData>({
    queryKey: [QueryKey.getCertificatesHandle],
    queryFn: () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.certificatesHandle).add(
        'Handle',
        handle,
      )
      return APIFunctions.GET(qb.toString())
    },
    enabled: !!handle,
  })
}
