import type { UploadResponse } from '../../packages/api-web'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from 'rsuite'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomNumberInput,
  CustomText,
  CustomTextArea,
  DeleteAlert,
  ImagePicker,
  Spinner,
} from '../../component'
import {
  useCertificatesItem,
  useCertificateTypes,
  useDeleteCertificate,
  useIndustries,
  usePostCertificate,
  usePutCertificate,
  useUploadMedia,
} from '../../packages/api-web'

interface CheckBoxArray {
  handle?: string
  title?: string
  isSelect?: boolean
}

interface TypeData {
  handle?: string
  title?: string
}

function CreateCertificate() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { state } = useLocation()
  const [checkBoxArray, setCheckBoxArray] = useState<CheckBoxArray[]>([
    { handle: 'isCoreCertificate', title: t('core'), isSelect: false },
    { handle: 'isApprovalRequired', title: t('requiresApproval'), isSelect: false },
    { handle: 'isCompliance', title: t('regulatoryCompliance'), isSelect: true },
    { handle: 'isAttachmentRequired', title: t('requiresAttachment'), isSelect: false },
  ])
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [selectedCertificateType, setSelectedCertificateType] = useState<string>('')
  const [descriptionImage, setDescriptionImage] = useState(undefined)
  const [defaultDescriptionImage, setDefaultDescriptionImage] = useState<string>()
  const [isClearDescriptionImage, setIsClearDescriptionImage] = useState<boolean>(false)
  const [descriptionIcon, setDescriptionIcon] = useState(undefined)
  const [defaultDescriptionIcon, setDefaultDescriptionIcon] = useState<string>()
  const [isClearDescriptionIcon, setIsClearDescriptionIcon] = useState<boolean>(false)
  const [selectedValidityType, setSelectedValidityType] = useState<string>('')
  const [validAmount, setValidAmount] = useState<number>(0)
  const [selectedExpirationMetric, setSelectedExpirationMetric] = useState<string>('')
  const [expirationTime, setExpirationTime] = useState<number>(0)
  const [selectedIndustry, setSelectedIndustry] = useState<string>('')
  const [cost, setCost] = useState<number>(0)
  const [errorString, setErrorString] = useState<string[]>([])
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const validityTypeData: TypeData[] = [
    { handle: 'Year', title: 'Year' },
    { handle: 'Month', title: 'Month' },
    { handle: 'Week', title: 'Week' },
    { handle: 'Day', title: 'Day' },
  ]
  const { data: certificateTypes } = useCertificateTypes()
  const { data: industriesList } = useIndustries({})
  const { isPending: isCertificatePost, mutate: postCertificate } = usePostCertificate()
  const { mutate: uploadMediaMute } = useUploadMedia()
  const { isLoading, data: certificatesObj } = useCertificatesItem({
    handle: state?.selectedItem?.handle ?? undefined,
  })
  const { isPending: isCertificatePut, mutate: putCertificate } = usePutCertificate()
  const { isPending: isCertificateDelete, mutate: deleteCertificate } = useDeleteCertificate()

  useEffect(() => {
    if (state?.selectedItem) {
      const item = certificatesObj?.item
      setTitle(item?.title ?? '')
      setDescription(item?.description ?? '')
      setValidAmount(item?.validityPeriod ?? 0)
      setExpirationTime(item?.reminderBefore ?? 0)
      setCost(item?.cost ?? 0)
      setSelectedCertificateType(item?.certificateTypeHandle ?? '')
      setSelectedValidityType(item?.validityPeriodMetric ?? '')
      setSelectedExpirationMetric(item?.reminderBeforeMetric ?? '')
      setSelectedIndustry(item?.industryHandle ?? '')
      setDefaultDescriptionImage(
        certificatesObj?.item?.imageMediaItemDto
          ? certificatesObj?.item?.imageMediaItemDto?.url
          : undefined,
      )
      setDefaultDescriptionIcon(
        certificatesObj?.item?.iconMediaItemDto
          ? certificatesObj?.item?.iconMediaItemDto?.url
          : undefined,
      )
      const data = [
        {
          handle: 'isCoreCertificate',
          title: t('core'),
          isSelect: certificatesObj?.item?.isCoreCertificate,
        },
        {
          handle: 'isApprovalRequired',
          title: t('requiresApproval'),
          isSelect: certificatesObj?.item?.isApprovalRequired,
        },
        {
          handle: 'isCompliance',
          title: t('regulatoryCompliance'),
          isSelect: certificatesObj?.item?.isCompliance,
        },
        {
          handle: 'isAttachmentRequired',
          title: t('requiresAttachment'),
          isSelect: certificatesObj?.item?.isAttachmentRequired,
        },
      ]
      setCheckBoxArray(data)
    }
  }, [certificatesObj])

  const certificateData = useMemo(() => {
    const data = certificateTypes?.items
    return data
  }, [certificateTypes])

  const industriesData = useMemo(() => {
    const data = industriesList?.items
    return data
  }, [industriesList])

  const getMultiplier = (): number => {
    if (selectedValidityType === 'Year') {
      return selectedExpirationMetric === 'Year'
        ? 1
        : selectedExpirationMetric === 'Month'
          ? 12
          : selectedExpirationMetric === 'Week'
            ? 52
            : 365
    }
    if (selectedValidityType === 'Month') {
      return selectedExpirationMetric === 'Month'
        ? 1
        : selectedExpirationMetric === 'Week'
          ? 52
          : selectedExpirationMetric === 'Day'
            ? 365
            : 0
    }
    if (selectedValidityType === 'Week')
      return selectedExpirationMetric === 'Week' ? 1 : selectedExpirationMetric === 'Day' ? 365 : 0
    if (selectedValidityType === 'Day')
      return selectedExpirationMetric === 'Day' ? 1 : 0
    return 0
  }

  const getExpirationMaxValue = () => {
    return validAmount * getMultiplier()
  }

  const onTapSave = () => {
    if (title && selectedIndustry) {
      setErrorString([])
      const data = {
        title,
        certificateTypeHandle: selectedCertificateType,
        description,
        validityPeriod: validAmount,
        validityPeriodMetric: selectedValidityType,
        reminderBefore: expirationTime,
        reminderBeforeMetric: selectedExpirationMetric,
        industryHandle: selectedIndustry,
        cost,
        isAttachmentRequired: checkBoxArray?.find(i => i?.handle === 'isAttachmentRequired')
          ?.isSelect,
        isApprovalRequired: checkBoxArray?.find(i => i?.handle === 'isApprovalRequired')?.isSelect,
        isCompliance: checkBoxArray?.find(i => i?.handle === 'isCompliance')?.isSelect,
        isCoreCertificate: checkBoxArray?.find(i => i?.handle === 'isCoreCertificate')?.isSelect,
        numberCertificateItems: 0,
        numberUsersWithCertificateItems: 0,
        numberExpiredCertificateItems: 0,
        ...(!isClearDescriptionImage
          && certificatesObj?.item?.imageMediaItemDto && {
          imageMediaItemHandle: certificatesObj?.item?.imageMediaItemDto?.handle,
        }),
        ...(!isClearDescriptionIcon
          && certificatesObj?.item?.iconMediaItemDto && {
          iconMediaItemHandle: certificatesObj?.item?.iconMediaItemDto?.handle,
        }),
        ...(state?.selectedItem && {
          handle: certificatesObj?.item?.handle,
        }),
      }
      const requestParams: FormData = new FormData()
      if (descriptionImage || descriptionIcon) {
        ;[descriptionImage, descriptionIcon]?.forEach((item: any) => {
          requestParams.append('files', item)
        })
        uploadMediaMute(requestParams, {
          onSuccess: (response: UploadResponse) => {
            if (response.isSuccess) {
              const newObject = {
                ...data,
                ...(descriptionImage != undefined
                  && descriptionIcon != undefined && {
                  imageMediaItemHandle: response.items && response.items[0]?.handle,
                  iconMediaItemHandle: response.items && response.items[1]?.handle,
                }),
                ...(descriptionImage != undefined
                  && descriptionIcon == undefined && {
                  imageMediaItemHandle: response.items && response.items[0]?.handle,
                }),
                ...(descriptionImage == undefined
                  && descriptionIcon != undefined && {
                  iconMediaItemHandle: response.items && response.items[0]?.handle,
                }),
              }
              if (state?.selectedItem) {
                putCertificate(newObject, {
                  onSuccess: () => {
                    navigate(-1)
                  },
                })
              }
              else {
                postCertificate(newObject, {
                  onSuccess: () => {
                    navigate(-1)
                  },
                })
              }
            }
          },
        })
      }
      else {
        if (state?.selectedItem) {
          putCertificate(data, {
            onSuccess: () => {
              navigate(-1)
            },
          })
        }
        else {
          postCertificate(data, {
            onSuccess: () => {
              navigate(-1)
            },
          })
        }
      }
    }
    else {
      const errors = []
      if (!title)
        errors.push(t('equipmentTitle'))
      if (!selectedIndustry)
        errors.push(t('industryFieldRequired'))
      setErrorString(errors)
    }
  }

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      {(isCertificatePost || isCertificatePut || isCertificateDelete) && <Spinner />}
      {isDelete && (
        <DeleteAlert
          title={`${t('certificate')}: ${title}`}
          subTitle={`${t('deleteCampaign')}`}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsDelete(false)
          }}
          onLeftButton={() => {
            setIsDelete(false)
          }}
          onRightButton={() => {
            const data = {
              handle: certificatesObj?.item?.handle,
              contentType: 'certificate',
            }
            deleteCertificate(data, {
              onSuccess: () => {
                navigate(-1)
              },
            })
          }}
        />
      )}
      <CustomText variant="xlPoppinsBold">{t('experiences')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {t('createNewCertificate')}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          {state?.selectedItem && (
            <div className="flex flex-col lg:flex-row mb-3">
              <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg lg:mr-3">
                <CustomText variant="smPoppinsSemiBold">{t('numberOfUsersExperience')}</CustomText>
                <CustomText variant="smPoppinsSemiBold" className="!text-secondary">
                  {`${certificatesObj?.item?.numberUsersWithCertificateItems}`}
                </CustomText>
              </div>
              <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg max-lg:mt-3">
                <CustomText variant="smPoppinsSemiBold">
                  {t('numberOfExperiencesExpired')}
                </CustomText>
                <CustomText variant="smPoppinsSemiBold" className="!text-secondary">
                  {`${certificatesObj?.item?.numberExpiredCertificateItems}`}
                </CustomText>
              </div>
            </div>
          )}
          <div className="border border-stroke rounded-lg">
            <div className="py-3 px-5 border-b border-b-stroke">
              <CustomText variant="mdPoppinsSemiBold">{t('description')}</CustomText>
            </div>
            <div className="flex-1 p-5 overflow-y-auto scrollbar">
              <CustomInput
                title={t('title')}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
                placeholder={t('exHotWork')}
                className={`mb-3 ${!title && errorString.length > 0 ? 'outline-textDanger' : ''}`}
              />
              <CustomDropDown
                value={selectedCertificateType}
                className="!mb-2"
                title={t('certificateTypesList')}
                data={certificateData ?? []}
                labelField="title"
                valueField="handle"
                onSelect={(values) => {
                  setSelectedCertificateType(values)
                }}
                placeholder={t('selectExperienceType')}
              />
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <ImagePicker
                  title={`Description image ${t('maxPixels')}`}
                  defaultImage={defaultDescriptionImage}
                  buttonTitle={`${t('choosePicture')}`}
                  selectedImage={(item) => {
                    setIsClearDescriptionImage(false)
                    setDescriptionImage(item)
                  }}
                  onClearImage={() => {
                    setIsClearDescriptionImage(true)
                  }}
                />
                <div className="ml-8 max-lg:mt-3" />
                <ImagePicker
                  title={`Description icon ${t('maxPixels')}`}
                  defaultImage={defaultDescriptionIcon}
                  buttonTitle={`${t('choosePicture')}`}
                  selectedImage={(item) => {
                    setIsClearDescriptionIcon(false)
                    setDescriptionIcon(item)
                  }}
                  onClearImage={() => {
                    setIsClearDescriptionIcon(true)
                  }}
                />
              </div>
              <CustomTextArea
                title={`${t('description')} ${t('maxCharacters')}`}
                placeholder={`${t('writeDescription')}`}
                aria-multiline
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
              />
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1">
                  <CustomDropDown
                    value={selectedValidityType}
                    className="!mb-2"
                    title={`${t('validityType')}`}
                    data={validityTypeData}
                    labelField="title"
                    valueField="handle"
                    onSelect={(values) => {
                      setSelectedValidityType(values)
                    }}
                    placeholder={`${t('select')} ${t('validityType').toLowerCase()}`}
                  />
                </div>
                <div className="flex-1 max-lg:mt-3 lg:ml-5">
                  <CustomNumberInput
                    value={validAmount}
                    className="!mt-[7px] h-9"
                    title={t('validAmount')}
                    minValue={0}
                    onChange={(value) => {
                      setValidAmount(value as number)
                    }}
                  />
                </div>
              </div>
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1">
                  <CustomDropDown
                    value={selectedExpirationMetric}
                    className="!mb-2"
                    title={t('expirationMetric')}
                    data={validityTypeData}
                    labelField="title"
                    valueField="handle"
                    onSelect={(values) => {
                      setSelectedExpirationMetric(values)
                    }}
                    placeholder={`${t('select')}`}
                  />
                </div>
                <div className="flex-1 max-lg:mt-3 lg:ml-5">
                  <CustomNumberInput
                    disabled={!(validAmount > 0)}
                    className="!mt-[7px] h-9"
                    title={t('expirationTime')}
                    max={getExpirationMaxValue()}
                    minValue={0}
                    value={expirationTime}
                    onChange={(value) => {
                      setExpirationTime(value as number)
                    }}
                  />
                </div>
              </div>
              <div className="mb-3 mt-3 flex flex-col lg:flex-row">
                <div className="flex-1">
                  <CustomDropDown
                    value={selectedIndustry}
                    className="!mb-3"
                    title={`${t('industry')}`}
                    data={industriesData ?? []}
                    labelField="title"
                    valueField="handle"
                    onSelect={(values) => {
                      setSelectedIndustry(values)
                    }}
                    placeholder={`${t('select')} ${t('industry').toLowerCase()}`}
                  />
                </div>
                <div className="flex-1 max-lg:mt-3 lg:ml-5">
                  <CustomNumberInput
                    className="!mt-[7px] h-9"
                    title={t('costInSEK')}
                    value={cost}
                    onChange={(value) => {
                      setCost(value as number)
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 max-lg:mt-3">
                <CustomText variant="mdPoppinsSemiBold">{t('otherSettings')}</CustomText>
                {checkBoxArray
                && checkBoxArray.map((i, index) => (
                  <div className="flex items-center">
                    <CustomText
                      wrapText={false}
                      variant="smPoppinsRegular"
                      className="mr-10 w-1/5"
                    >
                      {i.title ?? ''}
                    </CustomText>
                    <Checkbox
                      value={i.handle}
                      checked={i?.isSelect}
                      onChange={() => {
                        const temp = [...checkBoxArray]
                        temp[index].isSelect = !i?.isSelect
                        setCheckBoxArray(temp)
                      }}
                    />
                  </div>
                ))}
              </div>
              {errorString.length > 0 && (
                <ul className="mt-5 list-disc pl-4">
                  {errorString.map((i, index) => (
                    <li key={index} className="text-textDanger">
                      {i}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 justify-end py-4 px-3">
          {state?.selectedItem && (
            <div className="flex-1">
              <CustomButton
                children={t('remove')}
                className="hidden sm:block"
                backgroundColor="bg-bthDisable"
                textColor="text-textPrimary"
                onClick={() => {
                  setIsDelete(true)
                }}
              />
            </div>
          )}
          <div className="flex gap-4 flex-wrap">
            {state?.selectedItem && (
              <CustomButton
                children={t('remove')}
                className="!px-4 sm:!px-5 block sm:hidden flex-1"
                backgroundColor="bg-bthDisable"
                textColor="text-textPrimary"
                onClick={() => {
                  setIsDelete(true)
                }}
              />
            )}
            <BorderButton
              children={t('cancel')}
              className="!px-2 sm:!px-5 flex-1"
              textColor="text-textPrimary"
              onClick={() => {
                navigate(-1)
              }}
            />
            {/* {state?.selectedItem && (
              <CustomButton
                children={t('restore')}
                className="!px-4 md:!px-14 flex-1"
                backgroundColor="bg-bthDisable"
                textColor="text-textPrimary"
                onClick={() => {}}
              />
            )} */}
            <CustomButton
              children={t('save')}
              className="flex-auto"
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={onTapSave}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCertificate
