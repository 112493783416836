import { TriangleIcon } from '../../../icons'
import { CustomText } from '../../atoms'

interface TimeLineProps {
  step: any[]
}

export function TimeLine({ step }: TimeLineProps) {
  return (
    <div className="flex-col relative">
      <div className="flex-col">
        {step.map((item, index) => (
          <div key={index} className="flex items-center py-3 relative">
            <div className="relative z-10 flex-shrink-0">{item.icon}</div>
            <div
              className="absolute left-[22.5px] w-1 bg-primary top-10"
              style={{ height: index == step.length - 1 ? '' : 'calc(100%)' }}
            />
            <div className="flex items-center ml-2">
              <TriangleIcon
                className={`relative left-1 ${index == step.length - 1 ? 'fill-white' : 'fill-bubble'}`}
              />
              <div
                className={`py-2 px-4 w-64 ${index == step.length - 1 ? 'bg-white' : 'bg-bubble'} rounded-xl`}
              >
                <CustomText
                  variant="lgPoppinsBold"
                  textColor={item.active ? 'text-textPrimary' : 'text-textSecondary'}
                >
                  {item.title}
                </CustomText>
                {item.description && (
                  <CustomText
                    variant="smPoppinsRegular"
                    textColor={item.active ? 'text-textPrimary' : 'text-textSecondary'}
                    className="pt-1"
                  >
                    {item.description}
                  </CustomText>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
