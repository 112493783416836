import type { CertificateTypeItem } from '../../packages/api-web'
import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme'
import { CustomText } from '../../component'
import CertificateType from '../../component/templates/CertificateType'
import { useCertificateTypes } from '../../packages/api-web'

function CreateCertificateType() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { data: certificateTypes } = useCertificateTypes()
  const [selectedItem, setSelectedItem] = useState<CertificateTypeItem>()

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const renderItem = (item: CertificateTypeItem, idx: string) => {
    return (
      <li
        key={idx}
        className={`px-3 cursor-pointer mt-2 flex h-7 items-center ${selectedItem?.handle == item?.handle ? 'bg-table-odd-bg' : ''}`}
        onClick={() => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate('/experiences/create_type/info', {
              state: { selectedItem: item },
            })
          }
          else {
            setSelectedItem(item)
          }
        }}
      >
        <CustomText variant="smPoppinsRegular">{item?.title ?? ''}</CustomText>
      </li>
    )
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsSemiBold">{t('experienceTypes')}</CustomText>
      <div className="mt-6 mb-4 items-center">
        <CustomText className="mb-[5px]" variant="lgPoppinsSemiBold">
          {t('overview')}
        </CustomText>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 flex-1 overflow-hidden">
        <div className="overflow-y-auto relative flex flex-col border border-stroke bg-white rounded-lg">
          <div className="px-3 flex justify-between h-10 border-b border-b-stroke items-center">
            <CustomText variant="smPoppinsSemiBold">{t('allTypes')}</CustomText>
            <CustomText
              className="!text-secondary"
              variant="smPoppinsSemiBold"
              onClick={() => {
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  navigate('/experiences/create_type/info')
                }
                else {
                  setSelectedItem(undefined)
                }
              }}
            >
              {`${t('createNew')} +`}
            </CustomText>
          </div>
          <div className="overflow-y-auto scrollbar flex-1">
            <ul>
              <FlatList list={certificateTypes?.items ?? []} renderItem={renderItem} />
            </ul>
          </div>
        </div>
        <div className="overflow-y-auto ml-5 col-span-2 max-lg:hidden">
          <CertificateType selectedItem={selectedItem} />
        </div>
      </div>
    </div>
  )
}

export default CreateCertificateType
