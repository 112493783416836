export function DraftCheck2Icon({ isEnable = false }) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none">
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="white"
      />
      <path
        d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z"
        strokeWidth={2}
        className={`${isEnable ? 'stroke-green' : 'stroke-[#999999]'}`}
      />
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        className={`${isEnable ? 'fill-green' : 'fill-[#999999]'}`}
      />
      <path
        d="M35.0004 15L23.7432 32.2043C23.6164 32.3983 23.4547 32.559 23.269 32.6751C23.0834 32.7912 22.8781 32.86 22.6671 32.8772C22.4562 32.8944 22.2445 32.8595 22.0463 32.7748C21.8482 32.69 21.6683 32.5574 21.5188 32.386L18.5898 29.0287"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0275 32.4903C17.891 32.6503 17.7282 32.778 17.5486 32.8653C17.3689 32.9527 17.1758 32.9984 16.9807 32.9997C16.7855 33.0009 16.592 32.9575 16.4115 32.8725C16.231 32.7875 16.067 32.6623 15.9289 32.5041L13 29.1523"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2732 15.1133L21.2695 27.3491"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0004 15L23.7432 32.2043C23.6164 32.3983 23.4547 32.559 23.269 32.6751C23.0834 32.7912 22.8781 32.86 22.6671 32.8772C22.4562 32.8944 22.2445 32.8595 22.0463 32.7748C21.8482 32.69 21.6683 32.5574 21.5188 32.386L18.5898 29.0287"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0275 32.4903C17.891 32.6503 17.7282 32.778 17.5486 32.8653C17.3689 32.9527 17.1758 32.9984 16.9807 32.9997C16.7855 33.0009 16.592 32.9575 16.4115 32.8725C16.231 32.7875 16.067 32.6623 15.9289 32.5041L13 29.1523"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2732 15.1133L21.2695 27.3491"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
