import { useLocation } from 'react-router-dom'
import CertificateType from '../../component/templates/CertificateType'

function CertificateTypeInfo() {
  const { state } = useLocation()

  return (
    <div className="p-5 h-full flex flex-col bg-white border border-stroke rounded-lg">
      <CertificateType selectedItem={state?.selectedItem} />
    </div>
  )
}

export default CertificateTypeInfo
