import FlatList from 'flatlist-react'
import { useTranslation } from 'react-i18next'
import { BorderButton, CustomText } from '../../atoms'

interface ChatEmptyProps {
  onPressMessage: (item: string) => void
}

export function ChatEmpty({ onPressMessage }: ChatEmptyProps) {
  const { t } = useTranslation('common')

  const topicStarters: string[] = t('ai_assistant.topicStarters', {
    returnObjects: true,
  }) as string[]

  // investigate why this strange form is needed (as unknown as string[])
  // const [list] = useState<string[]>(
  //   () => t('ai_assistant.topicStarters', { returnObjects: true }) as unknown as string[],
  // )

  const renderPerson = (item: string, idx: string) => {
    return (
      <li key={idx} className="cursor-pointer">
        <BorderButton
          className="sm:h-14 py-3 px-2 lg:px-4 border border-stroke rounded-md w-full text-left"
          children={item}
          textVariant="xsmPoppinsRegular"
          onClick={() => {
            onPressMessage(item)
          }}
        />
      </li>
    )
  }

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col flex-1 justify-center items-center text-center mt-10">
        <CustomText variant="mdPoppinsSemiBold">{t('giveAnswersTime')}</CustomText>
        <CustomText className="pt-2" variant="smPoppinsRegular">
          {t('giveAnswersDes')}
        </CustomText>
      </div>
      <div className="text-center mb-4">
        <CustomText variant="smPoppinsSemiBold">{t('example')}</CustomText>
        <ul className="mx-4 lg:mx-8 mt-3">
          <FlatList
            list={topicStarters}
            renderItem={renderPerson}
            displayGrid
            gridGap="15px"
            minColumnWidth="35%"
          />
        </ul>
      </div>
    </div>
  )
}
