import { UserJobRoleStatuses } from '@inphiz-shared/shared-models/src/F_Selfleadership/Constants/user-job-role-statuses'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAllJobRoles,
  useDeleteOfferJobRole,
  useGetAchievedRoles,
} from '../../../packages/api-web/Employee'
import { BorderButton, CustomText, Spinner } from '../../atoms'
import { DeleteAlert, PerformanceBadge, UnlockJobModal } from '../../molecules'

interface Params {
  handle?: string
}

export function EmployeePerformance({ handle }: Params) {
  const { t } = useTranslation('common')
  const [isAdd, setIsAdd] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>()
  const [badgehoveredIndex, setBadgehoveredIndex] = useState<number | null>(null)
  const [onGoingHoveredIndex, setOnGoingHoveredIndex] = useState<number | null>(null)
  const [careerHoveredIndex, setCareerHoveredIndex] = useState<number | null>(null)
  const { isLoading: isAllJobRoleLoad, data: jobRolesData } = useAllJobRoles({
    userHandle: handle,
  })
  const { isLoading: isAchievedJobLoad, data: achievedJobData } = useGetAchievedRoles({
    userHandle: handle,
  })
  const { isPending: isDeleteOffer, mutate: deleteOfferMutate } = useDeleteOfferJobRole()

  const careerData = useMemo(() => {
    const handlesInAchieved = new Set(
      achievedJobData?.item?.achievements
        ?.filter(item => item?.status !== UserJobRoleStatuses.unlocked)
        .map(item => item.handle),
    )
    const unmatchedData = jobRolesData?.items?.filter(item => !handlesInAchieved.has(item.handle))
    return unmatchedData
  }, [jobRolesData, achievedJobData])

  const myBadgesData = useMemo(() => {
    const result = achievedJobData?.item?.achievements?.filter(
      item =>
        item?.status == UserJobRoleStatuses.active || item?.status == UserJobRoleStatuses.suspended,
    )
    return result
  }, [achievedJobData])

  const onGoingData = useMemo(() => {
    const result = achievedJobData?.item?.achievements?.filter(
      item => item?.status == UserJobRoleStatuses.inProgress,
    )
    return result
  }, [achievedJobData])

  return (
    <>
      {(isAllJobRoleLoad || isAchievedJobLoad || isDeleteOffer) && <Spinner />}
      {(isAdd || (selectedItem && selectedItem?.status === UserJobRoleStatuses.locked)) && (
        <UnlockJobModal
          selectedItem={selectedItem}
          handle={handle}
          onCloseAlert={() => {
            setIsAdd(false)
            setSelectedItem(null)
          }}
        />
      )}
      {selectedItem && selectedItem?.status !== UserJobRoleStatuses.locked && (
        <DeleteAlert
          title={`${t('jobRole')}: ${selectedItem?.jobRole ? selectedItem?.jobRole?.title : selectedItem?.title}`}
          subTitle={t('deleteNewDes')}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setSelectedItem(null)
          }}
          onLeftButton={() => {
            setSelectedItem(null)
          }}
          onRightButton={() => {
            setSelectedItem(null)
            const data = {
              userHandle: handle,
              jobRoleHandles: [selectedItem?.handle],
            }
            deleteOfferMutate(data)
          }}
        />
      )}
      <div className="flex">
        <CustomText variant="xlPoppinsSemiBold" className="flex-1">
          {t('performance')}
        </CustomText>
        <BorderButton
          children={`+ ${t('addNew')}`}
          className="px-7"
          onClick={() => {
            setIsAdd(true)
          }}
        />
      </div>
      <div className="mt-5 flex-1 border border-stroke rounded-lg lg:gap-3 grid grid-cols-1 lg:grid-cols-3 content-start overflow-hidden overflow-y-auto scrollbar">
        <div>
          <CustomText
            variant="lgPoppinsSemiBold"
            className="py-5 px-5 flex-1 border-b border-stroke"
          >
            {t('myBadges')}
          </CustomText>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 py-2">
            {myBadgesData?.map((i, index) => {
              return (
                <button
                  onMouseEnter={() => setBadgehoveredIndex(index)}
                  onMouseLeave={() => setBadgehoveredIndex(null)}
                  onClick={() => {
                    setSelectedItem(i)
                  }}
                >
                  <PerformanceBadge data={i} isHover={badgehoveredIndex == index} />
                </button>
              )
            })}
          </div>
        </div>
        <div className="max-lg:border-t border-stroke flex-1">
          <CustomText
            variant="lgPoppinsSemiBold"
            className="py-5 px-5 flex-1 border-b border-stroke"
          >
            {t('ongoing')}
          </CustomText>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 py-2">
            {onGoingData?.map((i, index) => {
              return (
                <button
                  onMouseEnter={() => setOnGoingHoveredIndex(index)}
                  onMouseLeave={() => setOnGoingHoveredIndex(null)}
                  onClick={() => {
                    setSelectedItem(i)
                  }}
                >
                  <PerformanceBadge data={i} isHover={onGoingHoveredIndex == index} />
                </button>
              )
            })}
          </div>
        </div>
        <div className="max-lg:border-t border-stroke flex-1">
          <CustomText
            variant="lgPoppinsSemiBold"
            className="py-5 px-5 flex-1 border-b border-stroke"
          >
            {t('newcareerOpportunities')}
          </CustomText>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 py-2">
            {careerData?.map((i, index) => {
              return (
                <button
                  onMouseEnter={() => setCareerHoveredIndex(index)}
                  onMouseLeave={() => setCareerHoveredIndex(null)}
                  onClick={() => {
                    if (i?.jobRole?.minimalExperience == '00:00:00') {
                      setSelectedItem(i)
                    }
                  }}
                >
                  <PerformanceBadge
                    data={i}
                    isHover={
                      !!(careerHoveredIndex == index && i?.status !== UserJobRoleStatuses.locked)
                    }
                  />
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
