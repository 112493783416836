import type { TextColorVariants } from '../CustomText'
import { CustomText } from '../CustomText'

interface Params {
  title?: string
  className?: string
  backgroundColor?: string
  textColor?: TextColorVariants
}

export function TagView({
  title,
  className,
  backgroundColor,
  textColor = 'text-textPrimary',
}: Params) {
  return (
    <CustomText
      variant="smPoppinsRegular"
      textColor={textColor}
      className={`py-1 px-5 rounded-full ${className}`}
      style={{ backgroundColor }}
    >
      {title ?? ''}
    </CustomText>
  )
}
