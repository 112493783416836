import type { InputNumberProps } from 'rsuite'
import { InputNumber } from 'rsuite'
import { CustomText } from '../CustomText'

export interface CustomNumberInputProps extends InputNumberProps {
  title?: string
  rightTitle?: string
  minValue?: number
}

export function CustomNumberInput({
  title,
  rightTitle,
  minValue = 1,
  ...props
}: CustomNumberInputProps) {
  return (
    <div className="flex flex-col cm-number-input">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <div className="flex justify-start items-center mt-[5px]">
        <InputNumber min={minValue} defaultValue={1} {...props} />
        {rightTitle && <CustomText className="ml-2">{rightTitle}</CustomText>}
      </div>
    </div>
  )
}
