enum KnownUserMetadataTypes {
  Text = 'Text',
  Phone = 'Phone',
  City = 'City',
  State = 'State',
  Sms = 'Sms',
  Email = 'Email',
  Url = 'Url',
  StartDate = 'StartDate',
  FreeTextSelector = 'FreeTextSelector',
  Custom = 'Custom',
  CertificatesSummary = 'CertificatesSummary',
  SkypeForBusiness = 'SkypeForBusiness',
  MicrosoftTeams = 'MicrosoftTeams',
  ChatUserId = 'ChatUserId',
  HasJobRolesToUnlock = 'HasJobRolesToUnlock',
  HasJobRolesToAccept = 'HasJobRolesToAccept',
}

enum UserCertificateState {
  missingExperienceState = 'MissingExperienceState',
}

enum RequirementsTypes {
  certificate = 'Certificate',
  equipment = 'Equipment',
}

enum UserCertificatesConstantsLocal {
  required = 'cert-is-required',
}

enum UserJobRoleStatuses {
  locked = 'Locked',
  unlocked = 'Unlocked',
  inProgress = 'InProgress',
  active = 'Active',
  suspended = 'Suspended',
  removed = 'Removed', // For status history purpose
}

enum GetUserRoleModes {
  toUnlock = 'ToUnlock',
  toAccept = 'ToAccept',
  achievements = 'Achievements',
  all = 'All',
}

enum NotificationCategory {
  certificateAdded = 'CertificateAdded',
  userJobRoleIsSuspended = 'UserJobRoleIsSuspended',
  userJobRoleCanBeUnlocked = 'UserJobRoleCanBeUnlocked',
  certificateStatusUpdated = 'CertificateStatusUpdated',
  userJobRoleIsActivated = 'UserJobRoleIsActivated',
  userJobRoleIsAccepted = 'UserJobRoleIsAccepted',
  certificateExpiring = 'CertificateExpiring',
  likeAddedToPost = 'LikeAddedToPost',
  postAddedToGroup = 'PostAddedToGroup',
}

enum SocialPostType {
  readConfirmation = 'ReadConfirmation',
  default = 'default',
  readConfirmed = 'ReadConfirmed',
}

enum ContentType {
  like = 'like',
  comment = 'comment',
  news = 'news',
  groupwallpost = 'groupwallpost',
}

const RelationTypes = [
  {
    name: 'Mor',
    id: 0,
  },
  {
    name: 'Far',
    id: 1,
  },
  {
    name: 'Syster',
    id: 2,
  },
  {
    name: 'Bror',
    id: 3,
  },
  {
    name: 'Son',
    id: 4,
  },
  {
    name: 'Dotter',
    id: 5,
  },
  {
    name: 'Barn',
    id: 6,
  },
  {
    name: 'Vän',
    id: 7,
  },
  {
    name: 'Make/Maka',
    id: 8,
  },
  {
    name: 'Partner',
    id: 9,
  },
  {
    name: 'Familjemedlem',
    id: 10,
  },
  {
    name: 'Assistent',
    id: 11,
  },
  {
    name: 'Chef',
    id: 12,
  },
  {
    name: 'Rumskamrat',
    id: 13,
  },
  {
    name: 'Granne',
    id: 14,
  },
  {
    name: 'Läkare',
    id: 15,
  },
  {
    name: 'Vårdgivare',
    id: 16,
  },
  {
    name: 'Övrig',
    id: 17,
  },
]

enum UserMetadataKey {
  email = 'Email',
  mobile = 'Mobile',
  phone = 'Phone',
  startDate = 'StartDate',
}

enum Fields {
  trainer = 'tränare',
  tools = 'verktyg',
}

enum PermitStatus {
  // Initial step, when permit is created but not submitted for signing. No permit document will be generated. Permit can be edited.
  draft = 0,

  // When permit is submitted for signing. No permit document will be generated. Permit can be edited.
  waitingSignature = 1,

  // When permit is signed by all signatories and activated. Approval step has Finalized type and Approved action. Permit document will be generated. Permit can't be edited.
  active = 2,

  // When permit is not signed by someone. Approval step has Finalized type and Denied action. No permit document will be generated. Final state. Can be replaced by Archived status. Permit can't be edited.
  denied = 3,

  // When permit is expired by EndDate. Permit was generated but now we delete it. Final state, can be replaced by Archived status. Permit can't be edited.
  expired = 4,

  // When permit is archived, typically when new a new permit is created for the same user or when permit was disabled by manager. This status can replace all other statuses. Final state. Permit can't be edited.
  archived = 5,
}

enum PermitStepType {
  // Initial step, when permit is created but not submitted for signing
  draft = 0,

  // When permit is submitted for signing
  submitted = 1,

  // When permit is signed or rejected by Issuer
  issuerSignature = 2,

  // When permit is signed(Acknowledged) by Holder
  holderSignature = 3,

  // When permit is finalized. Could be Approved or Denied
  finalized = 4,
}

enum PermitStepAction {
  // For Signature Steps
  signed = 0,
  rejected = 1,

  // For Finalized Steps
  approved = 10,
  denied = 11,
}

enum PermitSignType {
  reject = 0,
  sign = 1,
}

export {
  ContentType,
  Fields,
  GetUserRoleModes,
  KnownUserMetadataTypes,
  NotificationCategory,
  PermitSignType,
  PermitStatus,
  PermitStepAction,
  PermitStepType,
  RelationTypes,
  RequirementsTypes,
  SocialPostType,
  UserCertificatesConstantsLocal,
  UserCertificateState,
  UserJobRoleStatuses,
  UserMetadataKey,
}
