import type { CertificateTypeItem } from '../Employee'
import { APIConst, QueryKey } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'

export function usePutCertificateType() {
  return useMutation({
    mutationFn: async (params: CertificateTypeItem) => {
      const data = {
        item: params,
      }
      const result = await APIFunctions.PUT(APIConst.certificateType, data)
      return result
    },
    onSuccess(_: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getCertificateTypes],
      })
    },
  })
}
