import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'

interface Params {
  permitHandle?: string
}

export function usePostSignFlowActivate() {
  return useMutation({
    mutationFn: async (data: Params) => {
      const result = await APIFunctions.POST(
        `${APIConst.permits.signFlowActivation}?permitHandle=${data?.permitHandle}`,
        {},
      )
      return result
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getPermitHandle, variables?.permitHandle],
      })
    },
  })
}
