import type { PermitsItem } from './usePermitsList'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.getPermits] })
}

export function usePostPermits() {
  return useMutation({
    mutationFn: async (data: PermitsItem) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(APIConst.permits.getPermits, params)
      return result
    },
    onSuccess,
  })
}
