import type { CertificateItem } from '../Employee'
import { APIConst, QueryKey } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'

export function usePostCertificate() {
  return useMutation({
    mutationFn: async (params: CertificateItem) => {
      const data = {
        item: params,
      }
      const result = await APIFunctions.POST(
        APIConst.employeesPerformance.certificateCategory,
        data,
      )
      return result
    },
    onSuccess(_: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getCertificate],
      })
    },
  })
}
