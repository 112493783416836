import type { PermitsItem } from './usePermitsList'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

const PermitsDataDto = new DataItemApiResponse<PermitsItem>()
export type PermitItem = typeof PermitsDataDto

interface Params {
  handle?: string
}

export function usePermitHandle({ handle = '' }: Params) {
  return useQuery<PermitItem>({
    queryKey: [QueryKey.getPermitHandle, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.permits.getPermitHandle).add('Handle', handle)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
