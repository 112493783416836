import type { filterItem } from '../../../screen/EquipmentList'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

export interface PermitsItem {
  startDate?: string
  endDate?: string
  status?: number
  holder?: {
    handle?: string
    displayName?: string
    profileImageHandle?: string
    jobTitle?: string
    department?: string
  }
  issuer?: {
    handle?: string
    displayName?: string
  }
  creator?: {
    handle?: string
    displayName?: string
  }
  fields?: Array<{
    name?: string
    value?: string
  }>
  approvalSteps?: Array<{
    stepType?: number
    stepAction?: number
    details?: string
    timestamp?: string
    initiatedBy?: {
      displayName?: string
    }
  }>
  certificates?: Array<{
    title?: string
    handle?: string
    imageMediaItemDto?: {
      url?: string
    }
  }>
  handle?: string
}

const PermitsDataDto = new DataCollectionApiResponse<PermitsItem>()
export type PermitsData = typeof PermitsDataDto

interface Params {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
  filterData?: filterItem[]
}

export function usePermitsList({
  itemPerPage = 20,
  skip = 0,
  searchTxt = '',
  filterData = [],
}: Params) {
  return useQuery<PermitsData>({
    queryKey: [QueryKey.getPermits],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.permits.getPermits)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('ParametersWithValues[IncludeHolder]', 'True')
        .add('ParametersWithValues[IncludeApprovalSteps]', 'True')
        .add('ParametersWithValues[IncludeIssuer]', 'True')
      if (searchTxt?.length > 0) {
        qb.add('ParametersWithValues[HolderDisplayName]', searchTxt)
      }
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        qb.add('ParametersWithValues[HolderJobTitleHandle]', filterData[0]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        qb.add('ParametersWithValues[HolderDepartmentName]', filterData[1]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[2]?.selectedData) {
        qb.add('ParametersWithValues[IssuerUserHandle]', filterData[2]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[3]?.selectedData) {
        qb.add('ParametersWithValues[PermitStatus]', filterData[3]?.selectedData)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
