import type { UserItem } from '../Social'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

const IssuerListDto = new DataCollectionApiResponse<UserItem>()
export type IssuerData = typeof IssuerListDto

interface Params {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
}

export function useIssuerList({ itemPerPage = 20, skip = 0, searchTxt = '' }: Params) {
  return useQuery<IssuerData>({
    queryKey: [QueryKey.getIssuer],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.permits.getIssuer)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('filter', searchTxt)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
