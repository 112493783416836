import { UserMetadataFields } from '@inphiz-shared/shared-models/src/F_Users/user-metadata-fields'

enum EncryptedStorageKey {
  userSession = 'user_session',
  isFirstTime = 'is_first_time',
  certificateCategoryList = 'certificate_category_list',
  isLogOut = 'is_logout',

  certificateType = 'certificate_type',
  menuList = 'menu_list_2',
  languageCode = 'language_code',
}

const DisplayKey = [
  UserMetadataFields.email,
  'Personnummer',
  'Personnummer1',
  UserMetadataFields.phone,
  UserMetadataFields.mobile,
  UserMetadataFields.city,
  UserMetadataFields.startDate,
  UserMetadataFields.department,
  UserMetadataFields.officeLocation,
  'EmergencyContacts',
  UserMetadataFields.state,
  UserMetadataFields.manager,
  UserMetadataFields.contactEmail,
]

export { DisplayKey, EncryptedStorageKey }
