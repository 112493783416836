import { useEffect, useRef, useState } from 'react'
import { CloseIcon, ImagePlaceHolder } from '../../../icons'
import { CustomButton } from '../CustomButton'
import { CustomText } from '../CustomText'

export interface ImagePickerProps {
  title?: string
  buttonTitle?: string
  defaultImage?: string
  selectedImage?: (item: any) => void
  onClearImage?: () => void
}

export function ImagePicker({
  title,
  buttonTitle,
  defaultImage,
  selectedImage,
  onClearImage,
}: ImagePickerProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [avatarURL, setAvatarURL] = useState<string>()

  useEffect(() => {
    setAvatarURL(defaultImage)
  }, [defaultImage])

  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    const cachedURL = URL.createObjectURL(fileUploaded)
    setAvatarURL(cachedURL)
    selectedImage?.(fileUploaded)
  }

  return (
    <div className="flex flex-col">
      {title && (
        <CustomText variant="mdPoppinsSemiBold" className="mb-3">
          {title}
        </CustomText>
      )}
      <div className={`relative self-start ${avatarURL ? 'border border-stroke rounded-lg' : ''}`}>
        {avatarURL ? (
          <img src={avatarURL} className="h-48 w-48 rounded-lg" />
        ) : (
          <ImagePlaceHolder />
        )}
        {avatarURL && (
          <div
            className="bg-white absolute -top-2 -right-2 rounded-full p-1 border"
            onClick={() => {
              setAvatarURL('')
              onClearImage?.()
            }}
          >
            <CloseIcon className="h-4 w-4" />
          </div>
        )}
      </div>
      <CustomButton
        children={buttonTitle ?? ''}
        backgroundColor="bg-bthDisable"
        textColor="text-textPrimary"
        className="self-start mt-3"
        style={{ paddingLeft: 20, paddingRight: 20 }}
        onClick={() => {
          handleClick()
        }}
      />
      <input type="file" onChange={handleChange} ref={hiddenFileInput} hidden />
    </div>
  )
}
