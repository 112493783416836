import { CheckboxCheck, SettingIcon } from '../../../icons'
import { CustomText } from '../../atoms'

export interface ExperienceItemProps {
  title: string
  image?: string
}

export function ExperienceItem({ title, image }: ExperienceItemProps) {
  return (
    <button className="relative flex flex-col py-5 w-40 md:w-48 bg-bubble rounded-lg items-center justify-center border border-stroke">
      {/* {!isCheck ? <CheckboxEmpty className='absolute top-2 right-2' /> : */}
      <CheckboxCheck className="absolute top-2 right-2" />
      {image ? <img src={image} /> : <SettingIcon className="fill-black h-16 w-16" />}
      <div className="px-4 py-1 bg-white rounded-full border border-stroke mt-2 ">
        <CustomText variant="smPoppinsBold">{title}</CustomText>
      </div>
    </button>
  )
}
