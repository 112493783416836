import type { CertificateItem } from '../Employee'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.getCertificate] })
}

export function useDeleteCertificate() {
  return useMutation({
    mutationFn: async (params: CertificateItem) => {
      const data = {
        item: params,
      }
      const qb = new QueryBuilder(APIConst.employeesPerformance.certificateCategory)
      const result = await APIFunctions.DELETE(qb.toString(), data)
      return result
    },
    onSuccess,
  })
}
