import type { ValueType } from 'rsuite/esm/Checkbox'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Checkbox, CheckboxGroup } from 'rsuite'
import defaultTheme from 'tailwindcss/defaultTheme'
import {
  type CertificateTypeItem,
  type UploadResponse,
  useDeleteCertificateType,
  usePostCertificateType,
  usePutCertificateType,
  useUploadMedia,
} from '../../../packages/api-web'
import {
  BorderButton,
  CustomButton,
  CustomInput,
  CustomText,
  CustomTextArea,
  ImagePicker,
  Spinner,
} from '../../atoms'
import { DeleteAlert } from '../../molecules'

interface Params {
  selectedItem?: CertificateTypeItem
}

function CertificateType({ selectedItem }: Params) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const checkBoxArray = [
    { handle: 'isShownInQuickLinks', title: 'Show in quick links in mobile app' },
    // { handle: 'isUsedForPermits', title: 'Include for Körtillstånd' },
  ]
  const [selectCheck, setSelectCheck] = useState<ValueType[]>([])
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [selectedIconImage, setSelectedIconImage] = useState(undefined)
  const [defaultIconImage, setDefaultIconImage] = useState<string>()
  const [error, setError] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { mutate: uploadMediaMute, isPending: isUploadImage } = useUploadMedia()
  const { mutate: updateMutate, isPending: updateType } = usePutCertificateType()
  const { mutate: postMutate, isPending: postType } = usePostCertificateType()
  const { mutate: deleteMutate, isPending: deleteType } = useDeleteCertificateType()

  useEffect(() => {
    setTitle(selectedItem?.title ? selectedItem?.title : '')
    setDescription(selectedItem?.description ? selectedItem?.description : '')
    setDefaultIconImage(
      selectedItem?.mediaItemIconDto?.url ? selectedItem?.mediaItemIconDto?.url : undefined,
    )
    if (selectedItem?.isShownInQuickLinks) {
      setSelectCheck([...selectCheck, 'isShownInQuickLinks'])
    }
    else {
      setSelectCheck([])
    }
  }, [selectedItem])

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onTapSave = () => {
    if (title) {
      const data = {
        title,
        description,
        isShownInQuickLinks: selectCheck?.includes('isShownInQuickLinks'),
        mediaItemIconHandle: selectedItem?.mediaItemIconHandle,
        key: selectedItem?.key ?? 'Other',
      }
      if (selectedItem) {
        const updateData = {
          ...data,
          handle: selectedItem?.handle ?? '',
        }
        if (selectedIconImage) {
          const requestParams: FormData = new FormData()
          requestParams.append('files', selectedIconImage)
          uploadMediaMute(requestParams, {
            onSuccess: (response: UploadResponse) => {
              if (response.isSuccess) {
                updateData.mediaItemIconHandle
                  = response?.items && response?.items?.length > 0 ? response?.items[0]?.handle : ''
                updateMutate(updateData, {
                  onSuccess: () => {
                    setSelectedIconImage(undefined)
                    if (
                      screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                    ) {
                      navigate(-1)
                    }
                  },
                })
              }
            },
          })
        }
        else {
          updateMutate(updateData, {
            onSuccess: () => {
              if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                navigate(-1)
              }
            },
          })
        }
      }
      else {
        if (selectedIconImage) {
          const requestParams: FormData = new FormData()
          requestParams.append('files', selectedIconImage)
          uploadMediaMute(requestParams, {
            onSuccess: (response: UploadResponse) => {
              if (response.isSuccess) {
                data.mediaItemIconHandle
                  = response?.items && response?.items?.length > 0 ? response?.items[0]?.handle : ''
                postMutate(data, {
                  onSuccess: () => {
                    setSelectedIconImage(undefined)
                    if (
                      screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                    ) {
                      navigate(-1)
                    }
                  },
                })
              }
            },
          })
        }
        else {
          postMutate(data, {
            onSuccess: () => {
              if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                navigate(-1)
              }
            },
          })
        }
      }
    }
    else {
      setError(true)
    }
  }

  return (
    <div className="flex flex-col h-full border border-stroke rounded-md bg-white">
      <div className="px-3 flex justify-between h-10 border-b border-stroke items-center">
        <CustomText variant="smPoppinsSemiBold">{t('typeInformation')}</CustomText>
      </div>
      <div className="px-5 py-5 flex-1 overflow-y-auto scrollbar">
        <CustomInput
          title={t('title')}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          placeholder={t('exHotWork')}
          className={`mb-3 ${!title && error ? 'outline-textDanger' : ''}`}
        />
        <CheckboxGroup
          name="checkbox-group"
          value={selectCheck}
          onChange={(value) => {
            setSelectCheck(value)
          }}
          className="my-5 min-w-fit max-h-60"
        >
          {checkBoxArray
          && checkBoxArray.map(i => (
            <div className="flex items-center">
              <CustomText wrapText={false} variant="smPoppinsRegular" className="mr-10 w-1/2">
                {i.title}
              </CustomText>
              <Checkbox value={i.handle} />
            </div>
          ))}
        </CheckboxGroup>
        <ImagePicker
          title={`Icon ${t('maxPixels')}`}
          defaultImage={defaultIconImage}
          buttonTitle={`${t('choosePicture')}`}
          selectedImage={(item) => {
            setSelectedIconImage(item)
          }}
        />
        <div className="mt-5" />
        <CustomTextArea
          title={`${t('description')}`}
          placeholder={`${t('writeDescription')}`}
          aria-multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
        />
      </div>
      <div className="flex flex-wrap gap-4 justify-end py-4 px-3">
        {selectedItem && (
          <div className="flex-1">
            <CustomButton
              children={t('remove')}
              className="hidden sm:block"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {
                setIsShowAlert(true)
              }}
            />
          </div>
        )}
        <div className="flex gap-4 flex-wrap">
          {selectedItem && (
            <CustomButton
              children={t('remove')}
              className="!px-4 sm:!px-5 block sm:hidden flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {
                setIsShowAlert(true)
              }}
            />
          )}
          <BorderButton
            children={t('cancel')}
            className="!px-2 sm:!px-5 flex-1"
            textColor="text-textPrimary"
            onClick={() => {
              if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                navigate(-1)
              }
              else {
                setTitle('')
                setDescription('')
                setSelectCheck([])
                setDefaultIconImage(undefined)
              }
            }}
          />
          <CustomButton
            children={t('save')}
            className="flex-auto"
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={() => {
              onTapSave()
            }}
          />
        </div>
      </div>
      {(updateType || isUploadImage || postType || deleteType) && <Spinner />}
      {isShowAlert && (
        <DeleteAlert
          title={`${t('certificateType')}: ${selectedItem?.title}`}
          subTitle={t('deleteCampaign')}
          leftButtonText={t('cancel')}
          rightButtonText={t('deletePosts')}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onLeftButton={() => {
            setIsShowAlert(false)
          }}
          onSingleButton={() => {
            setIsShowAlert(false)
          }}
          onRightButton={() => {
            const data = {
              type: 'default',
              handle: selectedItem?.handle,
              contentType: selectedItem?.contentType,
            }
            deleteMutate(data, {
              onSuccess: () => {
                setIsShowAlert(false)
                setTitle('')
                setDescription('')
                setSelectCheck([])
                setSelectedIconImage(undefined)
                setDefaultIconImage(undefined)
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  navigate(-1)
                }
              },
            })
          }}
        />
      )}
    </div>
  )
}

export default CertificateType
