import type { MediaItemDto } from '@inphiz-shared/shared-models/src/Base/media-item-dto'
import type { IMessage } from '../../templates/ChatList'
import { useTranslation } from 'react-i18next'
import { extractChatMessage } from '../../../helpers'
import { AIChatIcon } from '../../../icons'
import { CustomText } from '../../atoms'

interface ChatBubbleViewProps {
  message: IMessage
  userId: string
  isDashboard?: boolean
  onDocumentClick: (item: MediaItemDto) => void
}

export function ChatBubbleView({
  message,
  userId,
  isDashboard,
  onDocumentClick,
}: ChatBubbleViewProps) {
  const commonClasses = 'pb-4 w-4/5'
  const rightView = `${isDashboard ? 'py-1 mr-2' : 'py-3 mr-6'} px-4 rounded-md bg-bgColor shadow-md`
  const leftView = `${isDashboard ? 'py-1 ml-5' : 'py-3 ml-8'} px-4 rounded-md bg-white border border-stroke shadow-md`
  const { t } = useTranslation('common')
  const extractedContent = extractChatMessage(message.text)

  const isMyMessage = message?.user?.id === userId

  // filter out pfs only for now
  const citations = (message.citations ?? []).filter(
    i => i.mediaItem?.mediaContentType === 'application/pdf',
  )

  return (
    <li className={`${commonClasses} ${isMyMessage && 'ml-auto'} cursor-pointer`}>
      <div className={isMyMessage ? rightView : leftView}>
        {!isMyMessage && <AIChatIcon />}
        <CustomText className="my-2" variant="smPoppinsRegular">
          {extractedContent.remaining}
        </CustomText>
        {!isMyMessage && citations.length > 0 && (
          <>
            <CustomText className="mb-2" variant="smPoppinsSemiBold">
              {`${t('ai_assistant.citations')}:`}
            </CustomText>
            {citations.map((citation, index) => {
              return (
                <div
                  className="p-2 mb-2 last:mb-0 bg-bgColor rounded-lg"
                  key={index}
                  onClick={() => onDocumentClick(citation.mediaItem!)}
                >
                  <CustomText variant="smPoppinsRegular">
                    {citation.mediaItem!.name ?? ''}
                  </CustomText>
                </div>
              )
            })}
          </>
        )}
      </div>
    </li>
  )
}
