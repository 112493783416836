import type { KeyboardEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import { useCertificateList, useCertificateTypes, useIndustries } from '../../packages/api-web'

function Experiences() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [searchTxt, setSearchTxt] = useState('')
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const { data: certificateTypes } = useCertificateTypes()
  const { data: industriesList } = useIndustries({})
  const [filterData, setFilterData] = useState([
    {
      id: 1,
      header: t('typeOfExperience'),
      data: [],
      selectedData: null,
    },
    {
      id: 2,
      header: t('appendixMandatory'),
      data: [
        { handle: 'true', title: t('yes') },
        { handle: 'false', title: t('no') },
      ],
      selectedData: null,
    },
    {
      id: 3,
      header: t('regulatoryCompliance'),
      data: [
        { handle: 'true', title: t('yes') },
        { handle: 'false', title: t('no') },
      ],
      selectedData: null,
    },
    {
      id: 4,
      header: t('industry'),
      data: [],
      selectedData: null,
    },
  ])
  const {
    isLoading,
    data: certificateList,
    refetch,
  } = useCertificateList({
    itemPerPage,
    skip: skipItem,
    searchTxt,
    filterData,
  })

  const tableHeaderList = useMemo(
    () => [
      { title: t('title_experience'), id: 1, key: '' },
      { title: t('periodOfValidity'), id: 2, key: '' },
      { title: 'Requires attachment', id: 3, key: '' },
      { title: 'Amount of month', id: 4, key: '' },
      { title: t('typeOfExperience'), id: 5, key: '' },
      { title: 'Industry', id: 6, key: '' },
      { title: 'Compliance', id: 7, key: '' },
    ],
    [t],
  )

  const certificateData = useMemo(() => {
    const data = certificateList?.items?.map((i) => {
      return {
        ...i,
        periodOfValidity:
          i?.validityPeriod && i?.validityPeriod > 0
            ? `${i?.validityPeriod} ${i?.validityPeriodMetric}`
            : '0',
        amountOfMonth:
          i?.reminderBefore && i?.reminderBefore > 0
            ? `${i?.reminderBefore} ${i?.reminderBeforeMetric}`
            : '0',
      }
    })
    return data
  }, [certificateList])

  useMemo(() => {
    const newFilter: any = [...filterData]
    newFilter[0].data = certificateTypes?.items
    newFilter[3].data = industriesList?.items
    setFilterData(newFilter)
  }, [certificateTypes, industriesList])

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, refetchFn: () => void) => {
    if (event.key === 'Enter')
      refetchFn()
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('experiences')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData ?? null}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={t(item?.header)}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          onClick={() => {
            refetch()
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${certificateList?.totalCount ?? 0}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={e => handleKeyDown(e, refetch)}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
            <CustomButton
              children={t('create')}
              textVariant="smPoppinsMedium"
              className="px-4 sm:px-8 bg-secondary"
              onClick={() => {
                navigate('/experiences/certificate')
              }}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={certificateData}
              displayKeys={[
                'title',
                'periodOfValidity',
                'isAttachmentRequired',
                'amountOfMonth',
                'certificateType.title',
                'industry.title',
                'isCompliance',
              ]}
              onClick={(item) => {
                navigate('/experiences/certificate', { state: { selectedItem: item } })
              }}
            />
          </div>
          {certificateList && certificateList?.items && certificateList?.items.length > 0 && (
            <CustomPagination
              totalItem={certificateList?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Experiences
