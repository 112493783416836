import type { filterItem } from '../../../screen/GroupList'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { CertificateDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-dto'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

const CertificateItemDto = new CertificateDto()
export type CertificateItem = typeof CertificateItemDto

const CertificateListDto = new DataCollectionApiResponse<CertificateItem>()
export type CertificateData = typeof CertificateListDto

interface Params {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
  filterData?: filterItem[]
  isPermits?: boolean
}

export function useCertificateList({
  itemPerPage = 2147483647,
  skip = 0,
  searchTxt = '',
  filterData = [],
  isPermits = false,
}: Params) {
  return useQuery<CertificateData>({
    queryKey: [QueryKey.getCertificate],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.certificateCategory)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('filter', searchTxt)
      if (isPermits) {
        qb.add('ParametersWithValues[Metadata]', 'ufp')
      }
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        qb.add('ParametersWithValues[CertificateTypeHandle]', filterData[0]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        qb.add('ParametersWithValues[IsAttachmentRequired]', filterData[1]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[2]?.selectedData) {
        qb.add('ParametersWithValues[IsCompliance]', filterData[2]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[3]?.selectedData) {
        qb.add('ParametersWithValues[IndustryHandle]', filterData[3]?.selectedData)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
